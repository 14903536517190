.adyen-checkout-radio-button {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
  border: none;
  background: none;
  cursor: pointer;
  padding: 4px;
  color: #00112c;
  font-size: 1em;
  font-weight: 400;
}

.adyen-checkout-radio-button[aria-checked=false]:hover > .adyen-checkout-radio-button__radio {
  border-color: #99a3ad;
  box-shadow: 0 0 0 2px #d4d9db;
  cursor: pointer;
}

.adyen-checkout-radio-button__radio {
  background-color: #fff;
  border: 1px solid #b9c4c9;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;
}
[dir=rtl] .adyen-checkout-radio-button__radio {
  right: 16px;
  left: auto;
}

.adyen-checkout-radio-button__radio::after {
  content: "";
  display: block;
  position: relative;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease-out;
}

.adyen-checkout-radio-button__radio--selected {
  background-color: #0075ff;
  border: 0;
  transition: all 0.3s ease-out;
}

.adyen-checkout-radio-button__radio--selected:hover {
  box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.4);
}

.adyen-checkout-radio-button__radio--selected::after {
  transform: translateY(-50%) scale(1);
}