.adyen-checkout_upi-mode-selection-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  margin-top: 0;
  margin-bottom: 7px;
}

.adyen-checkout__segmented-control--upi-margin-bottom {
  margin-bottom: 16px;
}

.adyen-checkout-upi-area-intent .adyen-checkout__button--pay {
  margin-top: 16px;
}

.adyen-checkout-upi-area-qr-code .adyen-checkout__button--pay {
  margin-top: 0;
}